import "reset-css";
import "./style.css";

let isNavOpen = false;
let scrollPosition;

const functionalityNav = () => {
	const $hamburg = document.querySelector(`.list-item--hamburger`);
	const $mobileNavContainer = document.querySelector(
		`.mobile-navigation__container`
	);
	const $mobileNavigationOverlay = document.querySelector(
		`.mobile-navigation__overlay`
	);
	const $mobileNavCloseContainer = document.querySelector(
		`.mobile-navigation__close`
	);
	const $closeNavButton = document.querySelector(`.nav-close--button`);
	const $navList = document.querySelector(`.navigation__list`);
	const navListItemsMobile = document.querySelectorAll(
		`.mobile-navigation__list-item`
	);

	const heightMobileNav = $mobileNavContainer.offsetHeight;

	$hamburg.addEventListener(`click`, () => {
		if (!isNavOpen) {
			$mobileNavContainer.style.transform = `translateY(${0}px)`;
			$mobileNavContainer.style.opacity = 1;
			$mobileNavContainer.style.pointerEvents = "inherit";

			$mobileNavigationOverlay.style.backgroundColor = `rgba(0, 0, 0, 0.5)`;

			$mobileNavCloseContainer.style.pointerEvents = `inherit`;

			$closeNavButton.style.transform = `rotate(135deg) scale(1)`;
			$closeNavButton.style.opacity = 1;

			scrollPosition = window.scrollY;
			isNavOpen = true;
		}
	});

	const animateCloseNav = () => {
		$mobileNavContainer.style.transform = `translateY(-${heightMobileNav}px)`;
		$mobileNavContainer.style.opacity = 1;
		$mobileNavContainer.style.pointerEvents = "none";

		$mobileNavigationOverlay.style.backgroundColor = `rgba(0, 0, 0, 0)`;

		$mobileNavCloseContainer.style.pointerEvents = `none`;

		$closeNavButton.style.transform = `rotate(0deg) scale(0)`;
		$closeNavButton.style.opacity = 0;

		isNavOpen = false;
	};

	$closeNavButton.addEventListener(`click`, animateCloseNav);

	navListItemsMobile.forEach((item) => {
		item.addEventListener(`click`, animateCloseNav);
	});
};

const setButtonsActive = () => {
	let releaseDate = new Date("2024-12-08");
	releaseDate.setHours(10);
	const date = new Date();
	console.log(date);

	const allCtaButtons = document.querySelectorAll(`.subscribe__cta-button`);

	if (releaseDate < date) {
		console.log("Release has started!");
		allCtaButtons.forEach((button) => {
			button.classList.remove(`button--disabled`);
		});
	}
};

const generateLessonDates = () => {};

const init = () => {
	functionalityNav();
	generateLessonDates();
	setButtonsActive();
};

init();
